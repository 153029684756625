import { UploadFile } from "@mui/icons-material";

import axios from "axios";
 
const apiUrl = 'https://demo1.zeasale.com/chat/'; 
 
const api = {
  fetchData: async (page, limit, query,sessionId,uuid) => {
    try {      
      const response = await axios.post(`${apiUrl}run_module`, { query,sessionId,uuid }, {
        params: { page, limit },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },
 
  fetchFiles: async (page, limit, query,sessionId,uuid) => {
    try {      
      const response = await axios.post(`${apiUrl}run_files`, { query,sessionId,uuid }, {
        params: { page, limit },
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },
 
  uploadFile: async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file); 
      const response = await axios.post(`${apiUrl}upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "File upload failed");
    }
  },
};
 
export default api;
