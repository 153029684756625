import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  MenuItem,
  Select,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import api from "../../services/api";
import { v4 as uuidv4 } from 'uuid';

function DashboardNew() {
  const [prompts, setPrompts] = useState([{ query: "", uuid: uuidv4(), tableData: [], sqlQuery: "", loading: false, page: 0, rowsPerPage: 5, totalRows: 0, isRendered: false, iframeKey: uuidv4() }]);
  const [tabIndex, setTabIndex] = useState(0);
   const [error, setError] = useState("");
  const [sessionId, setSessionId] = useState(uuidv4()); 

  const handleAddPrompt = () => {
    setPrompts([...prompts, { query: "", uuid: uuidv4(), tableData: [], sqlQuery: "", loading: false, page: 0, rowsPerPage: 5, totalRows: 0, isRendered: false, iframeKey: uuidv4() }]);
  };

  const getRowsPerPageOptions = (totalRows) => {
    if (totalRows <= 5) {
      return [5];
    }
    else if (totalRows <= 10) {
      return [5, 10];
    } else if (totalRows <= 50) {
      return [5, 10, 20, 30, 40, 50];
    } else if (totalRows <= 100) {
      return [5, 10, 20, 40, 60, 80, 100];
    } else {
      return [5, 10, 25, 50, 100, 500];
    }
  };

  const handleRowsPerPageChange = (promptIndex, event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      updatedPrompts[promptIndex].rowsPerPage = newRowsPerPage;
      updatedPrompts[promptIndex].page = 0; // Reset page to 0
      return updatedPrompts;
    });
    // Trigger handleAsk to fetch data with the new rowsPerPage value
    handleAsk(promptIndex);
  }; 


  const setPage = (promptIndex, newPage) => {
    setPrompts((prevPrompts) => {
      const updatedPrompts = [...prevPrompts];
      updatedPrompts[promptIndex].page = newPage;
      return updatedPrompts;
    });
    handleAsk(promptIndex); // Fetch new data when the page changes
  };
  const renderPagination = (promptIndex, page, rowsPerPage, totalRows) => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const rowsPerPageOptions = getRowsPerPageOptions(totalRows);

    if (totalRows > 0) {
      return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: '10px' }}>
          <Grid item>
            <Typography variant="body1">Total Records: {totalRows}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Select
                  value={rowsPerPage}
                  onChange={(event) => handleRowsPerPageChange(promptIndex, event)}
                  displayEmpty
                  variant="outlined"
                  size="small"
                >
                  {rowsPerPageOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(promptIndex, 0)} disabled={page === 0}>
                  <FirstPage />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(promptIndex, Math.max(page - 1, 0))} disabled={page === 0}>
                  <NavigateBefore />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ display: 'inline', margin: '0 15px' }}>
                  {page + 1}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(promptIndex, Math.min(page + 1, totalPages - 1))} disabled={page >= totalPages - 1}>
                  <NavigateNext />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(promptIndex, totalPages - 1)} disabled={page >= totalPages - 1}>
                  <LastPage />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  }; 

  const handleQueryChange = (index, value) => {
    const newPrompts = [...prompts];
    newPrompts[index].query = value;
    newPrompts[index].error = ""; // Reset error on new query
    setPrompts(newPrompts);
  };

  const handleAsk = async (index) => {
    const newPrompts = [...prompts];
    newPrompts[index].loading = true;
    newPrompts[index].error = "";
    setPrompts([...newPrompts]);

    try {
      const response = await api.fetchData(newPrompts[index].page + 1, newPrompts[index].rowsPerPage, newPrompts[index].query, sessionId, newPrompts[index].uuid);
      if (response && response.response) {
        newPrompts[index].tableData = response.response;
        newPrompts[index].sqlQuery = response.sql;
        newPrompts[index].totalRows = response.count || 0;
        newPrompts[index].isRendered = true;
        newPrompts[index].iframeKey = uuidv4(); // Update iframe key to force reload
      } else {
        newPrompts[index].tableData = [];
        newPrompts[index].totalRows = 0;
        newPrompts[index].isRendered = true; 
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      newPrompts[index].error = "Failed to fetch data. Please try again.";
    } finally {
      newPrompts[index].loading = false;
      setPrompts([...newPrompts]);
    }
  };

  const renderTableAndGraph = (prompt, index) => {
    if (prompt.error) {
      return (
        <Typography color="error" variant="body1">
          {prompt.error}
        </Typography>
      );
    }

    if (!prompt.isRendered) return null;

    return (
      <Grid container spacing={2} key={prompt.uuid} sx={{ marginTop: '30px'}}>
        {/* Table Section */}
        <Grid item xs={6}>
          <TableContainer component={Paper} style={{ maxHeight: '600px', overflow: 'auto' }}>         
            <Table stickyHeader>
              {prompt.tableData.length > 0 && (
                <>
                  <TableHead>
                  <TableRow sx={{ backgroundColor: "#3E324D" }}>
                  <TableCell sx={{ color: "#ffffff", fontWeight: 'bold', backgroundColor: "#3E324D" }}>S.No</TableCell>
                      {Object.keys(prompt.tableData[0]).map((header) => (
                       <TableCell
                       key={header}
                       sx={{
                         color: "#ffffff",
                         fontWeight: 'bold',
                         backgroundColor: "#3E324D"
                       }}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>      
                  <TableBody>
                    {prompt.tableData.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>{prompt.page * prompt.rowsPerPage + rowIndex + 1}</TableCell>
                        {Object.values(row).map((value, cellIndex) => (
                          <TableCell key={cellIndex}>{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}

              {!prompt.loading && prompt.tableData.length === 0 && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={100} align="center">
                      No Data
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>

            {renderPagination(index, prompt.page, prompt.rowsPerPage, prompt.totalRows)}
          </TableContainer>
        </Grid>

        {/* Visualization Section */}
        <Grid item xs={6}>
          <iframe
            key={prompt.iframeKey} // Use iframeKey to force reload
            src={`https://demo1.zeasale.com/chat/dashboard/${prompt.uuid}`} 
            style={{ width: '100%', height: '600px', border: 'none' }}
            title={`Graph-${prompt.uuid}`}
          />
        </Grid>
      </Grid>
    );
  }; 

  return (
    <Box>
      {/* Main content */}
      <Box sx={{ marginTop: "30px" }}>
        {prompts.map((prompt, index) => (
          <Box key={prompt.uuid}>
            <Box sx={{ display: "flex", marginBottom: "10px" ,marginTop: "10px" }}>
              <TextField
                fullWidth
                label={`Enter query ${index + 1}`}
                value={prompt.query}
                onChange={(e) => handleQueryChange(index, e.target.value)}
                multiline
                minRows={1}
                maxRows={5}
                sx={{ flex: 1, marginRight: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAsk(index)}
                disabled={!prompt.query || prompt.loading}
              >
                {prompt.loading ? <CircularProgress size={24} /> : "ASK"}
              </Button>
            </Box>

            {renderTableAndGraph(prompt, index)}
          </Box>
        ))}

        {prompts.every((p) => p.isRendered || p.error) && (
          <Button variant="contained" color="secondary" onClick={handleAddPrompt} sx={{ marginTop: "20px" }}>
            Add Prompt
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default DashboardNew;
