import logo from './logo.svg';
import './App.css';
import Dashboard from './pages/dashboard/Dashboard'; 
import DashboardNew from './pages/dashboard/DashboardNew';

function App() {
  return (
    <div className="App">    
     <Dashboard />
    </div>   
  );
}

export default App;
