import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  MenuItem,
  Select,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import Editor from '@monaco-editor/react';
import { v4 as uuidv4 } from 'uuid';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";
import api from "../../services/api";
import DashboardNew from "./DashboardNew"; // Import the DashboardNew component
import DashboardFile from "./DashboardFile"; // Import the DashboardNew component

function Dashboard() {
  const [query, setQuery] = useState("");
  const [submittedQuery, setSubmittedQuery] = useState("");
  const [tableData, setTableData] = useState([]);
  const [sqlQuery, setSqlQuery] = useState("");
  const [error, setError] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [uuid] = useState(uuidv4());
  const [iframeKey, setIframeKey] = useState(uuidv4());
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [additionalInfo, setAdditionalInformation] = useState("");
  const [loadingInfo, setLoadingInfo] = useState(false);


  useEffect(() => {
    if (submittedQuery) {
      fetchDataOrFiles();
      setIframeKey(uuidv4());
    }
  }, [submittedQuery, page, rowsPerPage]);

  const fetchDataOrFiles = async () => {
    setLoading(true);
    try {
      let response;
      if (tabIndex === 1) {
        setLoadingInfo(true);
        response = await api.fetchFiles(query, uuid);
        if (response && response.success) {
          setAdditionalInformation(response.response);
        } else {
          setAdditionalInformation("");
        }
      }
      //else {        
      //   response = await api.fetchData(page + 1, rowsPerPage, query, uuid);

      //   if (response && response.response) {
      //     setTableData(response.response);
      //     setSqlQuery(response.sql);
      //     setTotalRows(response.count || 0);
      //     setError("");
      //   } else {
      //     setError("No data found.");
      //     setTableData([]);
      //     setTotalRows(0);
      //   }
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please check the console for more details.");
      setTableData([]);
    } finally {
      setLoading(false);
      setLoadingInfo(false);
      setIsButtonDisabled(true);
    }
  };

  const handleFileUpload = async () => {
    if (selectedFile) {
      try {
        const response = await api.uploadFile(selectedFile);
        if (response && response.filename) {
          setFileName(response.filename);
        }

      } catch (error) {
        setFileName('');
      }
    }
  };

  const handleFileChange = (e) => {
    setFileName('');
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setSelectedFile(file);
    }
  };

  const handleSubmit = () => {
    setPage(0);
    setRowsPerPage(5);
    setIsButtonDisabled(true);
    setSubmittedQuery(query);
  };

  const handleRowsPerPageChange = (e) => {
    const newRowsPerPage = parseInt(e.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setIsButtonDisabled(false);
  };

  const getRowsPerPageOptions = (totalRows) => {
    if (totalRows <= 5) {
      return [5];
    }
    else if (totalRows <= 10) {
      return [5, 10];
    } else if (totalRows <= 50) {
      return [5, 10, 20, 30, 40, 50];
    } else if (totalRows <= 100) {
      return [5, 10, 20, 40, 60, 80, 100];
    } else {
      return [5, 10, 25, 50, 100, 500];
    }
  };

  const renderTableHead = () => {
    if (tableData.length > 0) {
      const headers = Object.keys(tableData[0]);
      return (
        <TableHead>
          <TableRow sx={{ backgroundColor: "#3E324D" }}>
            <TableCell sx={{ color: "#ffffff", fontWeight: 'bold', backgroundColor: "#3E324D" }}>S.No</TableCell>
            {headers.map((header) => (
              <TableCell
                key={header}
                sx={{
                  color: "#ffffff",
                  fontWeight: 'bold',
                  backgroundColor: "#3E324D"
                }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      );
    }
    return null;
  };

  const renderTableBody = () => {
    if (loading) {
      return null;
    } else if (tableData.length > 0) {
      return (
        <TableBody>
          {tableData.map((row, index) => (
            <TableRow
              key={index}
              sx={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "#f2f2f2" }}             >
              <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
              {Object.values(row).map((value, i) => (
                <TableCell key={i}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      );
    } else if (error || !tableData.length) {
      return (
        <TableBody>
          <TableRow>
            <TableCell colSpan={100} align="center">
              {error || "No Data"}
            </TableCell>
          </TableRow>
        </TableBody>
      );
    }
  };

  const renderPagination = () => {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const rowsPerPageOptions = getRowsPerPageOptions(totalRows);

    if (tableData.length > 0) {
      return (
        <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: "10px" }}>
          <Grid item>
            <Typography variant="body1">
              Total Records: {totalRows}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Select
                  value={rowsPerPage}
                  onChange={handleRowsPerPageChange}
                  displayEmpty
                  variant="outlined"
                  size="small"
                >
                  {rowsPerPageOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(0)} disabled={page === 0}>
                  <FirstPage />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))} disabled={page === 0}>
                  <NavigateBefore />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ display: "inline", margin: "0 15px" }}>
                  {page + 1}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))} disabled={page >= totalPages - 1}>
                  <NavigateNext />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => setPage(totalPages - 1)} disabled={page >= totalPages - 1}>
                  <LastPage />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',   // Border for the Box
        borderRadius: '8px',        // Optional: Rounded corners
        padding: '16px',            // Optional: Padding inside the box
        margin: '16px',             // Optional: Space around the box
        overflow: 'auto',           // Enables both horizontal and vertical scrolling
        maxHeight: '1000px',         // Set maximum height to trigger vertical scroll
        maxWidth: '100%',           // Set maximum width to trigger horizontal scroll if needed
      }}>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Dashboard Tabs">
        <Tab label="Data" />
        <Tab label="Files" />
        <Tab label="upload" />
      </Tabs>

      {/* Render content based on the active tab */}
      {tabIndex === 0 ? (
        <DashboardNew />
      ) : tabIndex === 1 ? (
        <DashboardFile />
      ) : (
        <Box sx={{ mt: 2, marginTop: '40px', position: 'relative' }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Button variant="contained" component="label">
                Choose File
                <input
                  type="file"
                  id="fileInput"
                  accept="application/pdf"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            </Grid>
            <Grid item container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="body1">
                  Selected File: {fileName || "None"}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!fileName}
                  onClick={handleFileUpload}
                >
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}

    </Box>
  );
}

export default Dashboard;